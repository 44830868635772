<template>
	<section class="section wow">
		<div
			class="preloader"
			style="margin-top: 200px"
		/>
	</section>
</template>

<script>

import { mapActions } from 'vuex';

export default {
    mounted() {
        this.authExternal(this.$route.query.upid).then(result => !IS_DEV && !IS_TEST ? window.location.href = result.path : false)
    },
    methods: {
        ...mapActions('user', [
            'authExternal'
        ])
    }
}
</script>
